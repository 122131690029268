<template>
  <div class="main">
    <Header/>
    <div class="d-flex align-items-stretch h-100" style="ovflow:hidden">
      <section style="width:20%;min-width: 200px;max-width:300px;">
        <!-- @open="handleOpen" @close="handleClose" -->
        <el-menu default-active="/points" class="h-100" router>
          <el-menu-item index="/points/log" :class="{'is-active': $route.path === '/points/log'}">
            <svg class="icon icon-yuyin" aria-hidden="true" style="width: 20px; height: 20px; fill: currentColor;margin-top: -4px;margin-right: 8px">
              <use xlink:href="#fanhan-jifenjilu"></use>
            </svg>
            <span slot="title">积分记录</span>
          </el-menu-item>
          <el-menu-item index="/points/senduser" :class="{'is-active': $route.path === '/points/senduser'}">
            <svg class="icon icon-yuyin" aria-hidden="true" style="width: 20px; height: 20px; fill: currentColor;margin-top: -4px;margin-right: 8px">
              <use xlink:href="#fanhan-a-jifenfafangyonghu"></use>
            </svg>
            <span slot="title">积分发放(用户)</span>
          </el-menu-item>
          <el-menu-item index="/points/sendteam" :class="{'is-active': $route.path === '/points/sendteam'}">
            <svg class="icon icon-yuyin" aria-hidden="true" style="width: 20px; height: 20px; fill: currentColor;margin-top: -4px;margin-right: 8px">
              <use xlink:href="#fanhan-a-jifenfafangfantuan"></use>
            </svg>
            <span slot="title">积分发放(泛团)</span>
          </el-menu-item>
          <el-menu-item index="/points/salarycheck" :class="{'is-active': $route.path === '/points/salarycheck'}">
            <svg class="icon icon-yuyin" aria-hidden="true" style="width: 20px; height: 20px; fill: currentColor;margin-top: -4px;margin-right: 8px">
              <use xlink:href="#fanhan-tixianshenhe"></use>
            </svg>
            <span slot="title">提现审核</span>
          </el-menu-item>
          <el-menu-item index="/points/withdraw" :class="{'is-active': $route.path === '/points/withdraw' || $route.path === '/points/withdraw-history'}">
            <svg class="icon icon-yuyin" aria-hidden="true" style="width: 20px; height: 20px; fill: currentColor;margin-top: -4px;margin-right: 8px">
              <use xlink:href="#fanhan-duigongtixian"></use>
            </svg>
            <span slot="title">对公提现</span>
          </el-menu-item>
          <el-menu-item index="/points/userbill" :class="{'is-active': $route.path === '/points/userbill'}">
            <svg class="icon icon-yuyin" aria-hidden="true" style="width: 20px; height: 20px; fill: currentColor;margin-top: -4px;margin-right: 8px">
              <use xlink:href="#fanhan-yonghuzhangdanmingxi"></use>
            </svg>
            <span slot="title">用户账单明细</span>
          </el-menu-item>
          <el-menu-item index="/points/exchange" :class="{'is-active': $route.path === '/points/exchange'}">
            <svg class="icon icon-yuyin" aria-hidden="true" style="width: 20px; height: 20px; fill: currentColor;margin-top: -4px;margin-right: 8px">
              <use xlink:href="#fanhan-huishuaishezhi"></use>
            </svg>
            <span slot="title">汇率设置</span>
          </el-menu-item>
        </el-menu>
      </section>
      <section class="pl-5 pr-5" style="flex:1;overflow:auto;">
        <router-view v-if="isRouterAlive"/>
      </section>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
export default {
  name: 'main',
  provide () {
    return {
      reload: this.reload
    }
  },
  components: {
    Header
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    // 导航事件
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    // 导航事件
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    }
  }
}
</script>

<style lang="less">
body {
  padding-top: 3.5rem;
}
a:hover {
  text-decoration: none;
}
.tool-box {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.btn-action {
  padding-top: 1rem;
}
.el-menu-item{
  color: #333333;
  svg{
    color: #909399;
  }
}
.is-active{
  color: #3775F6 ;
  svg{
    color: #3775F6 ;
  }
}
</style>
